export default function resourcesHub(perPageOptions: number[]) {
  return {
    selectedFilters: {},
    perPage: perPageOptions[2],
    resources: [],
    searchQuery: '',
    filteredResources: [],
    stages: [],
    tags: [],
    taxonomyData: [],
    page: 1,
    total_count: 0,

    get totalPages() {
      return Math.ceil(this.total_count / this.perPage);
    },

    scrollToTop() {
      const element = document.querySelector('.resource-hub');
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      }
    },

    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.applyFilters(false);
        this.scrollToTop();
      }
    },

    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.applyFilters(false);
        this.scrollToTop();
      }
    },

    goToPage(p) {
      if (typeof p === 'number' && p >= 1 && p <= this.totalPages) {
        this.page = p;
        this.applyFilters(false);
        this.scrollToTop();
      }
    },

    pagesToShow() {
      const pages = [];
      const totalPages = this.totalPages;
      const currentPage = this.page;
      const maxPagesToShow = 3;

      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage > 1) {
          pages.push(currentPage - 1);
        }

        pages.push(currentPage);

        if (currentPage < totalPages) {
          pages.push(currentPage + 1);
        }

        if (currentPage + 1 < totalPages - 1) {
          pages.push('...');
        }

        if (currentPage < totalPages) {
          pages.push(totalPages);
        }
      }

      return pages;
    },

    clearFilters() {
      this.selectedFilters = {};
      this.searchQuery = '';
      this.perPage = perPageOptions[2];
      this.applyFilters();
      this.updateUrl();
    },

    updateUrl() {
      let url = new URL(window.location.origin + window.location.pathname);
      let params = new URLSearchParams();

      Object.keys(this.selectedFilters).forEach((key) => {
        if (this.selectedFilters[key]) {
          params.set(key, this.selectedFilters[key]);
        }
      });

      if (this.perPage) params.set('perPage', this.perPage);
      if (this.searchQuery) params.set('search', this.searchQuery);

      let queryString = params.toString();
      let newUrl = queryString ? `${url}?${queryString}` : url;

      window.history.pushState({}, '', newUrl);
    },

    async fetchAllResources(
      searchQuery = '',
      filters = {},
      page = 1,
      perPage = 10
    ) {
      const params = new URLSearchParams();

      if (searchQuery) {
        params.set('search', searchQuery);
      }

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params.set(key, filters[key]);
        }
      });

      params.set('page', page.toString());
      params.set('perPage', perPage.toString());
      params.set('orderby', 'title');
      params.set('order', 'asc');

      const response = await fetch(
        `/api/takt/v1/resources?${params.toString()}`
      );
      const data = await response.json();
      return data;
    },

    applyFilters(resetPage = true) {
      if (!Array.isArray(this.resources)) return;

      let filters = this.selectedFilters;
      let searchQuery = this.searchQuery ? this.searchQuery.toLowerCase() : '';

      this.fetchAllResources(
        searchQuery,
        filters,
        this.page,
        this.perPage
      ).then((data) => {
        this.filteredResources = data.resources;
        this.total_count = data.total_count;

        if (resetPage) {
          this.page = 1;
        }
        this.updateUrl();
      });
    },

    async init() {
      try {
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((value, key) => {
          if (key === 'perPage') {
            this.perPage = parseInt(value, 10);
          } else if (key === 'search') {
            this.searchQuery = value;
          } else if (key === 'page') {
            this.page = parseInt(value, 10);
          } else {
            this.selectedFilters[key] = value;
          }
        });

        const data = await this.fetchAllResources();
        this.resources = data.resources;
        this.filteredResources = [...this.resources];
        this.total_count = data.total_count;

        const [
          stageResponse,
          subtopicResponse,
          formatResponse,
          typeResponse,
          languageResponse,
          costResponse,
          regionResponse,
          tagResponse
        ] = await Promise.all([
          fetch('/wp-json/wp/v2/stage_taxonomy'),
          fetch('/wp-json/wp/v2/subtopic_taxonomy'),
          fetch('/wp-json/wp/v2/format_taxonomy'),
          fetch('/wp-json/wp/v2/type_taxonomy'),
          fetch('/wp-json/wp/v2/language_taxonomy'),
          fetch('/wp-json/wp/v2/cost_taxonomy'),
          fetch('/wp-json/wp/v2/region_taxonomy'),
          fetch('/wp-json/wp/v2/tags')
        ]);

        const stages = await stageResponse.json();
        const subtopics = await subtopicResponse.json();
        const formats = await formatResponse.json();
        const types = await typeResponse.json();
        const languages = await languageResponse.json();
        const costs = await costResponse.json();
        const regions = await regionResponse.json();
        const tags = await tagResponse.json();

        this.taxonomyData = [
          ...stages,
          ...subtopics,
          ...formats,
          ...types,
          ...languages,
          ...costs,
          ...regions,
          ...tags
        ].map((item) => ({
          id: item.id,
          title: item.name || item.title,
          slug: item.slug
        }));

        this.applyFilters();
      } catch (error) {
        console.error('Error fetching resources or taxonomies:', error);
      }
    },

    getTitleByTaxonomyId(termId) {
      return (
        this.taxonomyData.find((item) => item.id === termId)?.title ||
        this.taxonomyData.find((item) => item.id === termId)?.name
      );
    }
  };
}
