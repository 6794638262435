import md5 from 'md5';
import header from './_header';

// An announcement component that will show a banner with the provided content and hash that content in order to show new announcements.
export default function announcement(content: string) {
  const contentHash = md5(content);
  const cookieName = `hide_announcement_${contentHash}`;
  const cookieValue = document.cookie.split('; ').find(row => row.startsWith(cookieName)) ? false : true;

  return {
    showAnnouncement: cookieValue,
    closeAnnouncement() {
      this.showAnnouncement = false;
      const date = new Date();
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // 1 day
      document.cookie = `${cookieName}=true; expires=${date.toUTCString()}; path=/;`;
      header.setHeaderHeight();
    }
  };
}