import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function (Alpine) {
  const targets = [];

  Alpine.directive(
    'emerge',
    (el, { value, expression, modifiers }, { evaluate, cleanup }) => {
      if (value == 'trigger' || value == 'target') {
        return;
      }

      let options = evaluate(expression.length > 0 ? expression : '{}');

      setUpElementStorageMap(el, value, modifiers, options);

      targets.push(el);
    }
  );

  document.addEventListener('alpine:initialized', () => {
    targets.forEach((el) => {
      if (el._x_emerge.scrub) {
        doScrubbedAnimation(el);

        return;
      }

      doAutoAnimation(el);
    });
  });
}

function setUpElementStorageMap(el, value, modifiers, options) {
  if (!el._x_emerge) {
    el._x_emerge = {
      from: {},
      to: {},
      target: el,
      trigger: el,
      start: 'top bottom',
      end: 'top center',
      scrub: false,
      debug: false
    };
  }

  if (modifiers.includes('debug')) {
    el._x_emerge.debug = true;
  }

  el._x_emerge.start = `${modifierValue(modifiers, 'start', 'top')} ${modifierValue(modifiers, 'start', 'bottom', 2)}`;

  if (modifiers.includes('scrub')) {
    el._x_emerge.scrub = true;
    el._x_emerge.end = `${modifierValue(modifiers, 'end', 'top')} ${modifierValue(modifiers, 'end', 'center', 2)}`;
  } else {
    el._x_emerge.rewind = `${modifierValue(modifiers, 'rewind', 'top')} ${modifierValue(modifiers, 'rewind', 'bottom', 2)}`;
    el._x_emerge.duration =
      Number(modifierValue(modifiers, 'duration', 500)) / 1000;
    el._x_emerge.delay = Number(modifierValue(modifiers, 'delay', 0)) / 1000;
  }

  el._x_emerge.once = modifiers.includes('once');
  el._x_emerge.reverse = modifiers.includes('reverse');

  if (value == 'from' || value == null) {
    el._x_emerge.from = options;
  }

  if (value == 'to') {
    el._x_emerge.to = options;
  }

  if (value == 'item') {
    el._x_emerge.from = options;
    el._x_emerge.trigger = el.closest('[x-emerge\\:trigger]') ?? el;
  }

  if (value == 'group') {
    el._x_emerge.from = {
      stagger: 0.1,
      ...options
    };

    el._x_emerge.target = el.querySelectorAll('[x-emerge\\:target]') ?? el;
  }

  if (value == 'batch') {
    el._x_emerge.from = {
      stagger: 0.1,
      ...options
    };
    el._x_emerge.batch = true;
    el._x_emerge.target = el.querySelectorAll('[x-emerge\\:target]') ?? el;
  }

  if (modifiers.includes('opacity')) {
    el._x_emerge.from.y = 0;
  }
}

function doScrubbedAnimation(el) {
  if (
    el._x_emerge.to.hasOwnProperty('z') ||
    el._x_emerge.from.hasOwnProperty('z')
  ) {
    gsap.set(el, { transformPerspective: 500 });
  }

  gsap.fromTo(el, el._x_emerge.from, {
    scrollTrigger: {
      trigger: el,
      scrub: el._x_emerge.scrub,
      start: el._x_emerge.start,
      end: el._x_emerge.end
    },
    ...el._x_emerge.to
  });
}

function doAutoAnimation(el) {
  const defaultOptions: {
    opacity: number;
    paused: boolean;
    ease: string;
    y?: number;
  } = {
    y: '150px',
    opacity: 0,
    paused: true,
    ease: 'power2.inOut'
  };

  if (el._x_emerge.batch) {
    gsap.set(el._x_emerge.target, { opacity: 0 });

    ScrollTrigger.batch(el._x_emerge.target, {
      start: 'top bottom',
      batchMax: 3,
      interval: 0.1,
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          stagger: { each: 0.15, grid: [1, 3] },
          overwrite: true
        }),
      onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, overwrite: true })
    });
  } else {
    gsap.from(el._x_emerge.target, {
      ...defaultOptions,
      ...el._x_emerge.from,
      delay: el._x_emerge.delay,
      duration: el._x_emerge.duration,
      scrollTrigger: {
        refreshPriority: 0,
        trigger: el._x_emerge.trigger,
        start: el._x_emerge.start,
        once: el._x_emerge.once,
        toggleActions: `play none none ${el._x_emerge.once ? 'none' : 'none'}`
      }
    });

    // ScrollTrigger.create({
    //   trigger: el._x_emerge.trigger,
    //   start: el._x_emerge.start,
    //   once: el._x_emerge.once,
    //   onEnter: () => animation.restart(true),
    //   onLeaveBack: () =>
    //     el._x_emerge.reverse
    //       ? animation.reverse()
    //       : !el._x_emerge.once && animation.pause(0)
    // });

    // ScrollTrigger.create({
    //   trigger: el._x_emerge.trigger,
    //   start: el._x_emerge.rewind,
    //   onLeaveBack: () =>
    //     el._x_emerge.reverse
    //       ? animation.reverse()
    //       : !el._x_emerge.once && animation.pause(0)
    // });
  }
}

function modifierValue(
  modifiers: any,
  key: string,
  fallback: any = false,
  position = 1
) {
  // If the modifier isn't present, use the default.
  if (modifiers.indexOf(key) === -1) return fallback;

  const rawValue = modifiers[modifiers.indexOf(key) + position];

  if (!rawValue) return fallback;

  return rawValue;
}
