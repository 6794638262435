export default {
  open: false,
  stuck: false,

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setHeaderHeight();
      window.addEventListener('resize', this.setHeaderHeight);
    });

    // Fallback to recalculate the height after a short delay
    setTimeout(this.setHeaderHeight, 100);
  },

  toggleNav() {
    this.open = !this.open;
  },

  setHeaderHeight() {
    setTimeout(() => {
      const header = document.querySelector('#site-header');
      if (header) {
        document.body.style.setProperty('--header-height', getComputedStyle(header).height);
      }
    }, 100);
  }
};
