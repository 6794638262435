export default function physicianMap() {
  return {
    locationFilterName: 'All',
    languageFilterName: 'All',
    genderFilterName: 'All',
    physicians: [],
    filteredPhysicians: [],
    locationFilter: '',
    languageFilter: '',
    genderFilter: '',
    markers: {},
    postalCode: '',
    userLocationMarker: null,
    map: null,

    async init() {
      try {
        await this.fetchAllTerms();

        const response = await fetch('/wp-json/wp/v2/physicians?per_page=100');
        const data = await response.json();

        this.physicians = await Promise.all(
          data.map(async (item) => {
            const locationTerms = Array.isArray(item.birthFacility_taxonomy)
              ? item.birthFacility_taxonomy
              : [item.birthFacility_taxonomy];
            const languageTerms = Array.isArray(item.language_taxonomy)
              ? item.language_taxonomy
              : [item.language_taxonomy];
            const genderTerms = Array.isArray(item.gender_taxonomy)
              ? item.gender_taxonomy
              : [item.gender_taxonomy];

            const locationNames = item.birthFacility_taxonomy.map(
              (termId) => this.locationTerms[termId] || 'Unknown Term'
            );
            const languageNames = item.language_taxonomy.map(
              (termId) => this.languageTerms[termId] || 'Unknown Term'
            );
            const genderNames = item.gender_taxonomy.map(
              (termId) => this.genderTerms[termId] || 'Unknown Term'
            );

            return {
              id: item.id,
              name: item.title.rendered,
              address: item.meta.address || '',
              clinicTitle: item.meta.clinicTitle || '',
              clinicLink: item.meta.clinicLink || '',
              clinicPhone: item.meta.clinicPhone || '',
              location: Array.isArray(item.birthFacility_taxonomy)
                ? item.birthFacility_taxonomy
                : [item.birthFacility_taxonomy],
              locationName: locationNames,
              languages: Array.isArray(item.language_taxonomy)
                ? item.language_taxonomy
                : [item.language_taxonomy],
              languageName: languageNames,
              gender: Array.isArray(item.gender_taxonomy)
                ? item.gender_taxonomy
                : [item.gender_taxonomy],
              genderName: genderNames,
              lat: item.meta.latitude,
              lng: item.meta.longitude,
              clinicTwoAddress: item.meta.clinicTwoAddress || '',
              clinicTwoTitle: item.meta.clinicTwoTitle || '',
              clinicTwoLink: item.meta.clinicTwoLink || '',
              clinicTwoPhone: item.meta.clinicTwoPhone || ''
            };
          })
        );

        this.physicians = this.physicians.filter(
          (item) => item.lat && item.lng
        );

        this.filteredPhysicians = [...this.physicians];
        this.initMap();
      } catch (error) {
        console.error('Error fetching physicians:', error);
      }
    },

    async fetchAllTerms() {
      try {
        const [locationTerms, languageTerms, genderTerms] = await Promise.all([
          fetch('/wp-json/wp/v2/birthFacility_taxonomy?per_page=100').then(
            (res) => res.json()
          ),
          fetch('/wp-json/wp/v2/language_taxonomy?per_page=100').then((res) =>
            res.json()
          ),
          fetch('/wp-json/wp/v2/gender_taxonomy?per_page=100').then((res) =>
            res.json()
          )
        ]);

        this.locationTerms = this.createTermDictionary(locationTerms);
        this.languageTerms = this.createTermDictionary(languageTerms);
        this.genderTerms = this.createTermDictionary(genderTerms);
      } catch (error) {
        console.error('Error fetching terms:', error);
      }
    },

    createTermDictionary(terms) {
      return terms.reduce((acc, term) => {
        acc[term.id] = term.name;
        return acc;
      }, {});
    },

    async searchByPostalCode() {
      if (!this.postalCode.trim()) {
        this.clearPostalCode();
        return;
      }
      //TODO: change this key when we launch
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.postalCode)}&key=AIzaSyDK-NrFKwl4TKZlhQzYI_87yJUzIHhP9Z0`;

      try {
        const response = await fetch(geocodeUrl);
        const data = await response.json();

        if (data.status !== 'OK') {
          console.error('Geocoding error:', data.status);
          return;
        }

        const location = data.results[0].geometry.location;
        this.centerMapOnUser(location.lat, location.lng);
        this.filterByProximity(location.lat, location.lng);

        const mapElement = document.getElementById('map');
        if (mapElement) {
          mapElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } catch (error) {
        console.error('Error fetching geocode data:', error);
      }
    },

    centerMapOnUser(lat, lng) {
      this.map.setCenter({ lat, lng });
      this.map.setZoom(14);

      if (this.userLocationMarker) {
        this.userLocationMarker.setMap(null);
      }

      this.userLocationMarker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        title: 'Your Location',
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: 'blue',
          fillOpacity: 1,
          strokeWeight: 1
        }
      });
    },

    filterByProximity(lat, lng) {
      const R = 3958.8;

      this.filteredPhysicians = this.physicians.filter((physician) => {
        const dLat = (physician.lat - lat) * (Math.PI / 180);
        const dLng = (physician.lng - lng) * (Math.PI / 180);

        const a =
          Math.sin(dLat / 2) ** 2 +
          Math.cos((lat * Math.PI) / 180) *
            Math.cos((physician.lat * Math.PI) / 180) *
            Math.sin(dLng / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;

        return distance <= 4;
      });

      this.clearMarkers();
      this.addMarkers(this.filteredPhysicians);
    },

    clearPostalCode() {
      this.postalCode = '';
      if (this.userLocationMarker) {
        this.userLocationMarker.setMap(null);
        this.userLocationMarker = null;
      }
      this.filteredPhysicians = [...this.physicians];
      this.clearMarkers();
      this.addMarkers(this.physicians);
    },

    centerMapOnPhysician(lat, lng, name, clinicTitle, clinicLink, address) {
      const physicianLocation = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };

      this.map.setCenter(physicianLocation);
      this.map.setZoom(14);

      const key = `${physicianLocation.lat},${physicianLocation.lng}`;
      const physicianData = {
        name,
        lat,
        lng,
        clinicTitle,
        clinicLink,
        address
      };

      if (this.markers[key]) {
        this.openCustomPopup(this.markers[key], this.markers[key].physicians);
      } else {
        const marker = new google.maps.Marker({
          position: physicianLocation,
          map: this.map,
          title: `${clinicTitle}, ${name}`,
          icon: this.getCustomMarkerIcon()
        });

        marker.addListener('click', () => {
          this.openCustomPopup(marker, [physicianData]);
        });

        this.markers[key] = marker;
        this.openCustomPopup(marker, [physicianData]);
      }

      const mapElement = document.getElementById('map');
      if (mapElement) {
        mapElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },

    groupPhysiciansByLocation(physicians) {
      const grouped = {};

      physicians.forEach((physician) => {
        const key = `${physician.lat},${physician.lng}`;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(physician);
      });

      return grouped;
    },

    initMap() {
      const vancouverBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(49.0024, -123.2247),
        new google.maps.LatLng(49.3843, -122.4223)
      );

      const mapStyles = [
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#aee4db' }]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [{ color: '#f2f2f2' }]
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [{ color: '#f2f2f2' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ color: '#f2f2f2' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry.fill',
          stylers: [{ color: '#f2f2f2' }]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#f2f2f2' }]
        }
      ];

      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 12.83,
        center: { lat: 49.2575101, lng: -123.1699566 },
        restriction: {
          latLngBounds: vancouverBounds,
          strictBounds: true
        },
        styles: mapStyles
      });

      this.addMarkers(this.physicians);
    },

    hideMarkers() {
      Object.values(this.markers).forEach((marker) => marker.setVisible(false));
    },

    clearMarkers() {
      Object.values(this.markers).forEach((marker) => marker.setMap(null));
      this.markers = {};
    },

    addMarkers(physicians) {
      const groupedPhysicians = this.groupPhysiciansByLocation(physicians);

      Object.keys(groupedPhysicians).forEach((key) => {
        const [lat, lng] = key.split(',').map(Number);
        const physiciansAtLocation = groupedPhysicians[key];

        if (this.markers[key]) {
          this.markers[key].setTitle(
            physiciansAtLocation
              .map((p) => `${p.clinicTitle}, ${p.name}`)
              .join('\n')
          );
          this.markers[key].physicians = physiciansAtLocation;
        } else {
          const marker = new google.maps.Marker({
            position: { lat, lng },
            map: this.map,
            title: physiciansAtLocation
              .map((p) => `${p.clinicTitle}, ${p.name}`)
              .join('\n'),
            icon: this.getCustomMarkerIcon()
          });

          marker.physicians = physiciansAtLocation;

          marker.addListener('click', () => {
            this.openCustomPopup(marker, physiciansAtLocation);
          });

          this.markers[key] = marker;
        }
      });
    },

    getCustomMarkerIcon() {
      return {
        path: 'M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z',
        fillColor: '#FEB393',
        fillOpacity: 1,
        scale: 2,
        strokeWeight: 0
      };
    },

    openCustomPopup(marker, physicians) {
      if (!Array.isArray(physicians)) {
        physicians = [physicians];
      }

      const groupedByClinic = physicians.reduce((acc, physician) => {
        const key = `${physician.clinicTitle}|${physician.address}`;
        if (!acc[key]) {
          acc[key] = {
            clinicTitle: physician.clinicTitle,
            clinicLink: physician.clinicLink,
            address: physician.address,
            physicians: []
          };
        }
        acc[key].physicians.push(physician.name);
        return acc;
      }, {});

      const content = document.createElement('div');
      content.className =
        'custom-popup bg-[#FFD9C4] pt-0 p-4 rounded-lg relative';

      content.innerHTML = Object.values(groupedByClinic)
        .map(
          (group) => `
                    <div class="font-bold text-lg mb-1">
                        ${group.clinicLink.length > 0 ? `<a href="${group.clinicLink}" target="_blank">${group.clinicTitle}</a>` : group.clinicTitle}
                        <p class="text-sm font-normal mt-0 mb-4">${group.address}</p>
                    </div>
                    
                    ${group.physicians
                      .map((name) => `<div class="text-sm">${name}</div>`)
                      .join('')}
                `
        )
        .join(
          '<hr style="margin-top:8px; margin-bottom:8px;" class="border-black border-0 h-px bg-black">'
        );

      if (this.infoWindow) {
        this.infoWindow.close();
      }

      this.infoWindow = new google.maps.InfoWindow({
        content: content
      });

      this.infoWindow.open(this.map, marker);
    },

    filterLocations() {
      this.map.setZoom(12);
      if (this.activePopup) {
        document.getElementById('map').removeChild(this.activePopup);
        this.activePopup = null;
      }

      this.filteredPhysicians = this.physicians.filter((p) => {
        const locationMatches =
          this.locationFilter === '' ||
          (Array.isArray(p.location) &&
            p.location.includes(parseInt(this.locationFilter)));
        const languageMatches =
          this.languageFilter === '' ||
          (Array.isArray(p.languages) &&
            p.languages.includes(parseInt(this.languageFilter)));
        const genderMatches =
          this.genderFilter === '' ||
          (Array.isArray(p.gender) &&
            p.gender.includes(parseInt(this.genderFilter)));

        return locationMatches && languageMatches && genderMatches;
      });
      this.hideMarkers();

      this.clearMarkers();
      this.addMarkers(this.filteredPhysicians);
    }
  };
}
